import React from "react";
import Relenti from "../card/RelentiBox";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import { Chart } from "react-google-charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { invokeApig } from "../../libs/awsLib";
import { Alert, Button, Spinner, Toast } from "react-bootstrap";
import { formatNumber } from '../../libs/formatter';

ReactFC.fcRoot(FusionCharts, Charts, Widgets, FusionTheme);

const getMonthsDefault = () => {
  return [
    ["", "", "", ""],
    ["Enero", 0, 0, 0],
    ["febrero", 0, 0, 0],
    ["Marzo", 0, 0, 0],
    ["Abril", 0, 0, 0],
    ["Mayo", 0, 0, 0],
    ["Junio", 0, 0, 0],
    ["Julio", 0, 0, 0],
    ["Agosto", 0, 0, 0],
    ["Septiembre", 0, 0, 0],
    ["Octubre", 0, 0, 0],
    ["Noviembre", 0, 0, 0],
    ["Diciembre", 0, 0, 0],
  ];
};
const getRankingData = () => {
  return [
    [
      "Element",
      "",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
      { type: "string", role: "tooltip", p: { html: true } }
    ]
  ];
}
const getIdleTimeDefault = () => {
  return [["Language", "", { type: "string", role: "tooltip", p: { html: true } }]];
}

const getDataFormat = (data, total) => {
  const percentage = (data[1] / total) * 100;
  return `<div style="padding:5px"><b>${data[0]}</b><br/><div class="text-nowrap">${formatNumber(data[1], '', '', 0)} (${formatNumber(percentage, '', '', 1)}%)</div></div>`;
};

const addTooltipFormat = (data) => {
  const total = data.slice(1).reduce((sum, item) => sum + item[1], 0);
  for (let i = 1; i < data.length; i++) {
    data[i].push(getDataFormat(data[i], total));
  }
}

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.defaultOptions = [
      { value: "units", label: "Locomotora(s)" },
      { value: "group", label: "Grupos(s)" },
      { value: "all", label: "Todas las locomotoras" },
    ];
    this.state = {
      loading: false,
      showError: false,
      error: '',
      selValue: [],
      selOptions: this.defaultOptions,
      configurations: [],
      assets: [],
      assetGroup: {},
      groups: [],
      startDate: new Date(),
      endDate: new Date(),
      idleTimeDetail: getIdleTimeDefault(),
      idleTimeTotal: getIdleTimeDefault(),
      idlePercent: 0,
      kilometers: 0,
      allMonths: getMonthsDefault(),
      rankingData: getRankingData(),
      filterShow: [],
      finalAssets: 0
    };
  }
  componentDidMount = async () => {
    const result = await this.getConfiguration();
    result.payload.sort((a, b) => a.index - b.index);
    const configurations = await result.payload.map((config) => {
      return config.value;
    });
    this.setState({ configurations: configurations });
  };
  getConfiguration() {
    return invokeApig({
      path: "/config/find",
      method: "POST",
      body: {},
    });
  }
  getUnits() {
    return invokeApig({
      path: "/asset/find",
      method: "POST",
      body: {
        state: "Activo",
        limit: 1000,
      },
    });
  }
  showUnits = async () => {
    let assets = this.state.assets;
    if (assets.length === 0) {
      const result = await this.getUnits();
      this.setState({ assets: result.payload });
      assets = result.payload;
    }
    const newOptions = await assets.map((unit) => {
      return { value: unit.code, label: unit.code };
    });
    newOptions.sort((a, b) => a.value.localeCompare(b.value));
    this.setState({ selOptions: newOptions });
  };
  getGroups() {
    return invokeApig({
      path: "/asset/group/find",
      method: "POST",
      body: {},
    });
  }
  showGroups = async () => {
    let groups = this.state.groups;
    if (groups.length === 0) {
      const result = await this.getGroups();
      const assetGroup = {};
      Object.keys(result.payload).map((group) => {
        result.payload[group].map((asset) => {
          assetGroup[asset.code] = group;
        });
      });
      this.setState({ groups: result.payload, assetGroup: assetGroup });
      groups = result.payload;
    }
    const newOptions = await Object.keys(groups).map((group) => {
      return { value: group, label: group };
    });
    newOptions.sort((a, b) => a.value.localeCompare(b.value));
    this.setState({ selOptions: newOptions });
  };
  handleSelectChange = (value) => {
    if (!value) {
      this.setState({ selValue: [], selOptions: this.defaultOptions });
      return;
    }
    var option = value.length ? value[0].value : "";
    //var last = value[value.length - 1];
    switch (option) {
      case "units":
        if (value.length === 1) {
          this.setState({ selOptions: [] });
          this.showUnits();
          this.setState({ selValue: value });
        } else {
          this.setState({ selValue: value });
        }
        break;
      case "group":
        if (value.length === 1) {
          this.setState({ selOptions: [] });
          this.showGroups();
          this.setState({ selValue: value });
        } else {
          this.setState({ selValue: value });
        }
        break;
      case "all":
        this.setState({ selValue: value, selOptions: [] });
        break;
      default:
        this.setState({ selValue: [], selOptions: this.defaultOptions });
        break;
    }
  };
  handleChangeStartDate = (newDate) => {
    this.setState({ startDate: newDate });
  };
  handleChangeEndDate = (newDate) => {
    this.setState({ endDate: newDate });
  };
  getIdleTime(group, asset) {
    const startDate = moment(this.state.startDate).format("YYYY-MM");
    const endDate = moment(this.state.endDate).format("YYYY-MM");
    const request = {
      date_initial: startDate,
      date_end: endDate,
    };
    if (group) {
      request["group"] = group;
    }
    if (asset) {
      request["asset"] = asset;
    }
    return invokeApig({
      path: "/asset/idletime",
      method: "POST",
      body: request,
    });
  }
  addEventAsset = (assetsEvents, event) => {
    if (!assetsEvents[event.asset]) {
      assetsEvents[event.asset] = {
        key: event.asset,
        value: 0
      }
    }
    assetsEvents[event.asset].value += event.idle_time;
  }
  filterEvents = (all_events, assets, groups, finalAssets, assetsEvents) => {
    const events = [];
    if (assets !== null) { //filtro por assets
      all_events.payload.map((event) => {
        if (finalAssets.includes(event.asset)) {
          events.push(event);
          this.addEventAsset(assetsEvents, event);
        }
      });
    } else {
      if (groups !== null) { //filtro por groups
        const assets_months = {};
        all_events.payload.map((event) => {
          if (finalAssets.includes(event.asset)) {
            const key = `${event.date_range}_${this.state.assetGroup[event.asset]}`;
            if (!assets_months[key]) {
              assets_months[key] = {
                asset: event.group,
                idle_time: 0,
                moving: 0,
                km: 0,
                date_range: event.date_range
              };
            }
            assets_months[key].idle_time += event.idle_time;
            assets_months[key].moving += event.moving;
            assets_months[key].km += event.km;
            this.addEventAsset(assetsEvents, event);
          }
        });
        events.push(...Object.values(assets_months));
      } else { //filtro por todos
        const months = {};
        all_events.payload.map((event) => {
          const key = event.date_range;
          if (!months[key]) {
            months[key] = {
              asset: 'Todos',
              idle_time: 0,
              moving: 0,
              km: 0,
              date_range: event.date_range
            };
          }
          months[key].idle_time += event.idle_time;
          months[key].moving += event.moving;
          months[key].km += event.km;
          this.addEventAsset(assetsEvents, event);
        });
        events.push(...Object.values(months));
      }
    }
    return events;
  }
  showAllIdleTime = async (assets, groups) => {
    const all_events = await this.getIdleTime(null, null);
    let idleTime = 0,
      moveTime = 0,
      kilometers = 0;
    const idleTimeDetail = getIdleTimeDefault();
    const idleAssets = {}
    const currentYear = new Date().getFullYear();
    const years = [
      "",
      `${currentYear - 2}`,
      `${currentYear - 1}`,
      `${currentYear}`,
    ];
    const allMonths = getMonthsDefault();
    allMonths[0] = years;
    let finalAssets = assets;
    if (groups !== null) {
      finalAssets = [];
      groups.map((group) => {
        const listAssets = this.state.groups[group].map((asset) => asset.code);
        finalAssets = finalAssets.concat(listAssets);
        return null;
      });
    }
    const assetsEvents = {};
    const events = this.filterEvents(all_events, assets, groups, finalAssets, assetsEvents);
    events.map((event) => {
      const idle_time = event.idle_time * 24;
      let asset = idleAssets[event.asset];
      if (!asset) {
        idleAssets[event.asset] = {key: event.asset, value: 0};
        asset = idleAssets[event.asset];
      }
      asset.value += idle_time;
      idleTime += idle_time;
      moveTime += event.moving * 24;
      kilometers += event.km;
      const yearMonth = event.date_range.split("-");
      const index = years.indexOf(yearMonth[0]);
      if (index !== -1) {
        allMonths[parseInt(yearMonth[1])][index] += idle_time;
      }
      return null;
    });
    Object.values(idleAssets).sort((a, b) => b.value - a.value).map((asset) => {
      idleTimeDetail.push([asset.key, asset.value]);
      return null;
    });
    const idleTimeTotal = getIdleTimeDefault();
    idleTimeTotal.push(["Total Relanti", idleTime]);
    idleTimeTotal.push(["Total Movimiento", moveTime]);
    const rankingData = getRankingData();
    const rankingColors = ["#cc8f50", "#eaab00", "#00778b", "#67cbcc", "#878b8e"]
    const assetsEventsSort = Object.values(assetsEvents).sort((a, b) => b.value - a.value);
    for (let i=0; i<5 && i<assetsEventsSort.length;i++) {
      rankingData.push([assetsEventsSort[i].key, assetsEventsSort[i].value * 24, rankingColors[i], null])
    }
    this.setState({finalAssets: assetsEventsSort.length});
    addTooltipFormat(idleTimeDetail);
    addTooltipFormat(idleTimeTotal);
    addTooltipFormat(rankingData);
    this.setState({ idleTimeDetail: idleTimeDetail });
    this.setState({ idleTimeTotal: idleTimeTotal });
    this.setState({ allMonths: allMonths });
    this.setState({ kilometers: kilometers });
    this.setState({ idlePercent: idleTime / (idleTime + moveTime) });
    this.setState({ rankingData: rankingData });
    this.setState({ loading: false });
  };
  handleClick = (evt) => {
    evt.preventDefault();
    this.setState({ loading: true, filterShow: this.state.selValue });
    if (this.state.selValue && this.state.selValue.length) {
      switch (this.state.selValue[0].value) {
        case "all": //buscar todos
          this.showAllIdleTime(null, null);
          break;
        case "group":
          const groups = this.state.selValue
            .slice(1)
            .map((value) => value.value);
          this.showAllIdleTime(null, groups);
          break;
        case "units":
          const assets = this.state.selValue
            .slice(1)
            .map((value) => value.value);
          this.showAllIdleTime(assets, null);
          break;
        default:
          this.setState({loading: false, showError: true, error: 'Debes seleccionar un filtro'});
      }
    } else {
      this.setState({loading: false, showError: true, error: 'Debes elegir un filtro para mostrar'});
    }
  };
  renderError() {
    const style = {
      position: 'absolute',
      zIndex: 9,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    };
    return (
      <Toast
        onClose={() => this.setState({showError: false})}
        show={this.state.showError}
        delay={3000}
        autohide
        style={style}>
        <Toast.Header>
          Error
        </Toast.Header>
        <Toast.Body>
          <Alert variant="danger">
            {this.state.error}
          </Alert>
        </Toast.Body>
      </Toast>
    );
  }
  render() {
    const { selValue, selOptions, finalAssets } = this.state;
    const options = {
      chart: {
        subtitle: "",
      },
      colors: ["#01598b", "#569ba3", "#c87f51", "#add8da", "#333c87"],
    };
    return (
      <div>
        <h1 className="text-center">Dashboard de ralentí</h1>
        <div className="filtro col-12">
          <div className="card custom-card mb-5">
            <div className="card-body">
              <div className="row">
                {this.renderError()}
                <div className="col-1" style={{display: 'flex', alignItems: 'center'}}>Filtro</div>
                <div className="col-8">
                  <Select
                    name="map-select"
                    isMulti
                    onChange={this.handleSelectChange}
                    options={selOptions}
                    value={selValue}
                    placeholder="Click para ver opciones"
                    noOptionsMessage={() => {
                      return "No hay opciones disponibles";
                    }}
                    className="select-multi"
                  />
                </div>
                <div className="col-2">
                  <div className="row">
                    <div className="col-6">
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                        selectsStart
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        placeholderText="Inicio"
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        width="100px"
                      />
                    </div>
                    <div className="col-6">
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleChangeEndDate}
                        selectsEnd
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        placeholderText="Fin"
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-1">
                  <Button variant="primary" onClick={this.handleClick} disabled={this.state.loading}>
                    {
                      this.state.loading ?
                      <Spinner animation="border" role="status">
                        <span className="sr-only">...</span>
                      </Spinner>
                      :
                      'Buscar'
                    }
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-2">

            <div className="panel-group cuadrado rounded">
              
              
              <div className="panel panel-primary">
                <div className="panel-heading"><b>Filtro</b></div>
                <div className="panel-body textovalor">{
                    this.state.filterShow && this.state.filterShow.length ?
                    <b>{this.state.filterShow[0].label} [{finalAssets}]</b>
                    :
                    <b>Filtros sin seleccionar</b>
                  }</div>
              </div>


              <div className="panel panel-primary">
                <div className="panel-heading"><b>Ralentí</b></div>
                <div className="panel-body textovalor2 d-flex flex-column text-center">
                  <b className="HORAS">{this.state.idleTimeTotal && this.state.idleTimeTotal.length > 2 ? formatNumber(this.state.idleTimeTotal[1][1], '', '', 0) : '-'}</b><b>Horas</b></div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading"><b>Kms recorrido</b></div>
                <div className="panel-body textovalor2 d-flex flex-column text-center"><b className="HORAS">{formatNumber(this.state.kilometers, '', '', 0)}</b> <b>Kms</b> </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading"><b>Fuente</b></div>
                <div className="panel-body textovalor d-flex flex-column text-center">
                  <b className="url">
                    <a href="https://seguridadonline.smartpartners.cl" target="_blank" rel="noopener noreferrer">Ir a plataforma</a>  
                  </b>
                </div>
              </div>

            </div>

            </div>

            <div className="col-12 col-md-10">
              <div className="row mt-1 mb-1">
                <div className="col-12 col-sm-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <Relenti
                        header="Rango de Ralentí"
                        type="tacometro"
                        config={this.state.configurations}
                        idlePercent={this.state.idlePercent}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <Relenti
                        header="% Ralentí"
                        type="linea"
                        idleTimeTotal={this.state.idleTimeTotal}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-1 mb-1">
                <div className="col-12 col-sm-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <Relenti
                        header="Ránking de ralentí"
                        type="ranking"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        rankingData={this.state.rankingData}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <Relenti
                        header="Ralentí"
                        type="barra"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        idleTimeTotal={
                          this.state.idleTimeTotal &&
                          this.state.idleTimeTotal.length > 2
                            ? formatNumber(this.state.idleTimeTotal[1][1], '', '', 0)
                            : null
                        }
                        idleTimeDetail={this.state.idleTimeDetail}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="card custom-card mb-5 mb-xs-4">
                    <div className="card-body">
                      <h6 className="card-title">
                        <b>Ralentí mensual total</b>{" "}
                      </h6>
                      <Chart
                        chartType="Bar"
                        width="100%"
                        height="300px"
                        data={this.state.allMonths}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Body;
