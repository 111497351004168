import React from "react";

import { Chart } from "react-google-charts";
import GaugeChart from "react-gauge-chart";
import moment from "moment";
import { formatNumber } from "../../libs/formatter";

const optionsPie = {
  title: "",
  legend: "none",
  pieSliceText: "label",
  with: 250,
  height: 250,
  slices: {
    0: { color: "#ecc846" },
    1: { color: "#e6e6e6" },
  },
  chartArea: {
    top: "10%",
    height: "90%",
    width: "90%",
  },
  tooltip: { isHtml: true }
};

const optionsRalenti = {
  title: "",
  legend: "none",
  pieSliceText: "label",
  with: 200,
  height: 250,
  slices: {
    0: { color: "#ecc846" },
    1: { color: "#e6e6e6" },
    2: { color: "#01598b" },
  },
  chartArea: {
    top: "10%",
    height: "80%",
    width: "80%",
  },
  tooltip: { isHtml: true }
};

const chartStyle = {
  height: 150,
  width: 270,
};

const options = {
  height: "330px",
  width: "100%",
  bar: { groupWidth: "95%" },
  legend: { position: "none" },
  tooltip: { isHtml: true }
};

const Relenti = (props) => {
  return (
    <div className="card mr-0 custom-card">
      <div className="card-body ">
        <h6 className="card-title">
          <b>{props.header}</b>{" "}
        </h6>
        {props.type === "ranking" && (
          <div className="ranking d-flex justify-content-center align-items-center">
            <Chart
              chartType="BarChart"
              data={props.rankingData}
              options={options}
            />
          </div>
        )}
        {props.type === "linea" && (
          <>
            <div className="row col-12">
              <div className="col-3 d-flex flex-column align-items-center">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Encendido</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {props.idleTimeTotal && props.idleTimeTotal.length > 2
                          ? formatNumber(
                              props.idleTimeTotal[1][1] +
                              props.idleTimeTotal[2][1]
                            , '', '', 0)
                          : "-"}{" "}
                        Hrs
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Ralentí</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {props.idleTimeTotal && props.idleTimeTotal.length > 2
                          ? formatNumber(props.idleTimeTotal[1][1], '', '', 0)
                          : "-"}{" "}
                        Hrs
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Movimiento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {props.idleTimeTotal && props.idleTimeTotal.length > 2
                          ? formatNumber(props.idleTimeTotal[2][1], '', '', 0)
                          : "-"}{" "}
                        Hrs
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-9">
                <Chart
                  chartType="PieChart"
                  data={props.idleTimeTotal}
                  options={optionsPie}
                  width={"100%"}
                  height={"200px"}
                />
              </div>
            </div>
          </>
        )}
        {props.type === "barra" && (
          <>
            <div className="row col-12">
              <div className="col-3">
                <div className="w-100">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Mes Inicio</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{moment(props.startDate).format("MM-YYYY")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="w-100">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Mes Fin</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{moment(props.endDate).format("MM-YYYY")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="w-100">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Total ralentí</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {props.idleTimeTotal ? props.idleTimeTotal : "-"} Hrs
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-9">
                <div className="pieChart">
                <Chart
                  chartType="PieChart"
                  data={props.idleTimeDetail}
                  options={optionsRalenti}
                />
                </div>
              </div>
            </div>
          </>
        )}
        {props.type === "tacometro" && (
          <>
            <>
              <div className=" card-body d-flex align-items-center justify-content-center titleTacometro">
                Indicadores del rango ralentí
              </div>
            </>
            <div className="tacometro">
              <div className="chartTacometro">
                <GaugeChart
                  style={chartStyle}
                  id="gauge-chart5"
                  textColor={"black"}
                  nrOfLevels={150}
                  arcsLength={props.config}
                  colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                  percent={props.idlePercent}
                  arcPadding={0.02}
                />
              </div>
              <div className="leyenda align-items-center justify-content-center">
                <div className="row">
                  <div className="col col-md4">
                    <span className="green">&nbsp;</span>
                    <span className="gauge-text">0 % - {" "}{props.config.length ? props.config[0] * 100 - 1 : "-"}%</span>
                  </div>
                  <div className="col col-md4">
                    <span className="yellow">&nbsp;</span>
                    <span className="gauge-text">{props.config.length ? props.config[0] * 100 : "-"}% -{" "} {props.config ? (props.config[0] + props.config[1]) * 100 - 1 : "-"}%</span>
                  </div>
                  <div className="col col-md4">
                    <span className="red">&nbsp;</span>
                    <span className="gauge-text">{props.config.length ? (props.config[0] + props.config[1]) * 100 : "-"}% - 100%</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Relenti;
